import {
  initializeIntersectionObserver, initializeMobileIntersectionObserver, onMouseOut, onMouseOver, resetStyles,
} from '@scripts/shared/list-gallery.shared';
import { isTouchDevice } from '@scripts/utilities';
import '@styles/acf-blocks/list-gallery.scss';

const listGallerySelector = '.list-gallery';

const mouseHoverEventListener = (listGallery, items) => {
  const imgContainer = listGallery.querySelector('.list-gallery__bg-image');
  imgContainer.style.backgroundImage = '';
  [...items].forEach((link) => {
    const arrow = link.querySelector('.list-gallery__item-arrow');
    const svg = link.querySelector('.list-gallery__item-logo');
    const title = link.querySelector('.list-gallery__item-title');
    const src = link.getAttribute('data-image');
    const text = link.querySelector('.list-gallery__item-text');

    link.addEventListener('mouseover', () => onMouseOver(imgContainer, arrow, svg, title, src, text, true));
    link.addEventListener('mouseout', () => onMouseOut(imgContainer, arrow, svg, title, src, text, true));

    if ((isTouchDevice() || !window.matchMedia('(min-width: 1024px)').matches)) {
      resetStyles(imgContainer, arrow, svg, title, src, text);
    }
  });
};

const initializeListGallery = () => {
  const listGallery = document.querySelector(listGallerySelector);
  const items = listGallery ? listGallery.querySelectorAll('.list-gallery__item') : null;
  const imgContainer = listGallery ? listGallery.querySelector('.list-gallery__bg-image') : null;
  const mainContainer = listGallery ? listGallery.querySelector('.list-gallery__container') : null;

  if (listGallery) {
    initializeIntersectionObserver(mainContainer, imgContainer);
    initializeMobileIntersectionObserver(mainContainer, imgContainer, items);
    const resizeObserver = new ResizeObserver(() => {
      mouseHoverEventListener(listGallery, items);
    });

    resizeObserver.observe(document.body);
  }
};

/**
 * Javascript to run immediately on page load
 */
window.addEventListener('load', initializeListGallery, false);
